<template>
  <div  id="mycard" class="card">
    <div id="toolbar" class="fixed-table-toolbar" style="padding-right: 20px;padding-left: 20px;padding-top: 10px;">
      <div class="form-group row">
        <label class="col-md-1 col-form-label font-weight-semibold">Type:</label>
        <div class="col">
          <select  id="cmbldgr" class="form-control" v-model="trntype" >
            <option value="301">B2B</option>
            <option value="302">B2C</option>
            <option value="303">Composistion</option>
            <option value="304">Exempted</option>
            <option value="305">Imported</option>
            <option value="306">Non GST</option>
            <option value="307">In Eligible</option>
            <option value="308">RCM</option>
          </select>
        </div>

        <label class="col-md-1 col-form-label">From:</label>
        <div class="col-md-2">
          <input type="date"  class="form-control" placeholder="From Date"  v-model="from_date" >
        </div>

        <label class="col-md-1 col-form-label">Upto:</label>
        <div class="col-md-2">
          <input type="date"  class="form-control" placeholder="Upto Date" :min="from_date" v-model="upto_date" >
        </div>
        <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData">Show</button>
      </div>

    </div>

    <div class="table-responsive">
      <table  id="trans-table" class="table table-xs table-columned table-hover" data-target=".context-table">
        <thead>
        <tr>
          <th class="d-none">id</th>
          <th style="width: 50px;">S.No</th>
          <th style="width: 110px;">Date</th>
          <th style="width: 110px;">Doc No</th>
          <th>Description</th>
          <th style="width: 100px;">HSN</th>
          <th style="width: 100px;">GST %</th>
          <th style="width: 150px;text-align: right">Taxable</th>
          <th style="width: 100px;text-align: right">I GST</th>
          <th style="width: 100px;text-align: right">C GST</th>
          <th style="width: 100px;text-align: right">S GST</th>
        </tr>
        </thead>
        <tbody  >
          <tr v-for="(trn,idx) in trans" >
            <td class="d-none">{{ trn._id}}</td>
            <td>{{idx + 1}}</td>
            <td>{{ trn.doc_date | formatDate }}</td>
            <td>{{trn.doc_no}}</td>
            <td>{{trn.group}}</td>
            <td>{{trn.hsn}}</td>
            <td>{{trn.gst_rate}}</td>
            <td style="width: 150px;text-align: right">{{trn.taxable_amt | indianFormat}}</td>
            <td style="width: 100px;text-align: right">{{trn.igst_amt | indianFormat}}</td>
            <td style="width: 100px;text-align: right">{{trn.cgst_amt | indianFormat}}</td>
            <td style="width: 100px;text-align: right">{{trn.sgst_amt | indianFormat}}</td>
          </tr>
        </tbody>
        <tfoot style="background-color: lightgrey;" class="font-weight-semibold">
          <tr >
            <td></td>
            <td></td>
            <td></td>
            <td style="text-align: right"> {{trnname }} Total</td>
            <td></td>
            <td></td>
            <td style="width: 150px;text-align: right">{{taxable_amt | indianFormat}}</td>
            <td style="width: 100px;text-align: right">{{igst_amt | indianFormat}}</td>
            <td style="width: 100px;text-align: right">{{cgst_amt | indianFormat}}</td>
            <td style="width: 100px;text-align: right">{{sgst_amt | indianFormat}}</td>
          </tr>
        </tfoot>
      </table>
    </div>


    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <a href="#" class="dropdown-item" >
          <i class="icon-file-eye"></i>Preview</a>

      </div>
    </div>
    <!-- End of Context Menu -->

    <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="225" >
      <form>
        <div class="card">
          <div class="card-header header-elements-inline" style="background-color: darkgrey;color: white;padding: 10px;">
            <h3 class="card-title">Date Range</h3>
            <div class="header-elements">
              <div class="list-icons">
                <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
              </div>
            </div>
          </div>

          <div class="card-body">

            <p/>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">From:</label>
              <div class="col-md-9">
                <input type="date" class="form-control"  v-model="from_date" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 col-form-label">To:</label>
              <div class="col-md-9">
                <input type="date" class="form-control" v-model="upto_date" />
              </div>
            </div>


            <div class="text-right">
              <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Filter<i class="icon-database-refresh ml-2"></i></button>
            </div>

          </div>
        </div>
      </form>
    </vmodal>

    <div class="modal" id="previewmodal"  >
      <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable" >
        <div class="modal-content" >

          <!-- Modal body -->
          <div class="modal-body" >
            <PreviewForm v-if="invoice.code > 1" v-bind:invoice="invoice" > </PreviewForm>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  import PreviewForm from '@/views/tms/vouchers/invoice/PurchaseInvoicePreview.vue'

  export default {
    name: 'gst3bdetailed',
    components:{
      PreviewForm
    },
    store,
    data () {
      return {
        current_ibr : localStorage.getItem('branch_code'),
        mytable: {},
        trans: [],
        trntype:0,
        trnname:'',
        invoice: JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":0,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}'),
        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        taxable_amt: 0,
        igst_amt:0,
        cgst_amt:0,
        sgst_amt:0,
      }
    },
    created () {
    },
    mounted () {
      let self = this;

      self.$data.mytable = $('#trans-table').children('tbody');



      $('.table').contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          let id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Preview') {
            self.invoicePreview(id);
          }
        }
      });

    },
    filters:{
      formatDate: function(value){
        try {
          return value.slice(0, 10).split('-').reverse().join('-');
        } catch (ex) {

        }
        return '';
      },
      indianFormat: function (value) {
        if(value !== 0)
          // return value.toFixed(2);
          return (new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' })).format(value);
        else
          return '';
      },
    },
    methods: {
      beforeOpen(){

      },
      beforeClose(){

      },
      closePreview() {
        this.$modal.hide('preview-window');
      },
      showFilter(){
        this.$modal.show('filter-window');
      },
      blockCard(){
        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
      },
      unblockCard(){
        $('#mycard').unblock();
      },

      loadData () {
        let self = this
        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.blockCard();

        self.$data.trans = [];
        self.$data.taxable_amt = 0;
        self.$data.igst_amt = 0;
        self.$data.cgst_amt = 0;
        self.$data.sgst_amt = 0;

        self.$data.trnname = $("#cmbldgr :selected").text();


        if (self.$data.trntype > 0) {


          fetch(`${process.env.VUE_APP_ROOT_API}v1/gst/3bdetailed/?trntype=${self.$data.trntype}&?ibr=${self.$data.current_ibr}&from=${self.$data.from_date}&upto=${self.$data.upto_date}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.accepted) {
              if (_.isArray(resp.data)) {

                self.$data.trans = resp.data;

                resp.data.forEach(function (trn,idx) {
                    self.$data.taxable_amt += trn.taxable_amt;
                    self.$data.igst_amt += trn.igst_amt;
                    self.$data.cgst_amt += trn.cgst_amt;
                    self.$data.sgst_amt += trn.sgst_amt;
                });

              }
            } else {
              swal({ title: 'Oops!', text: resp.message, type: 'error' })
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
          }).finally(function () {

            self.unblockCard();
            // $('#cmbldgr').focus();
          })
        } else {
          self.$data.disableLedger = false;
          $('#cmbldgr').focus();
          self.unblockCard();
        }
      },
      invoicePreview(id){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode: "cors",
          headers: userService.authHeader()
        };

        self.$data.invoice = {};
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
            $('#previewmodal').modal('show');

          } else {
            swal({ title: "Oops!", text: resp.message, type: "error" });
          }
        }).catch(function (err) {
          swal({ title: "Oh noes!", text: err.toString(), type: "error" });
        });

      },


    }
  }

</script>

<style scoped>

</style>
