<template>
  <FocusTrap>
  <div id="mycard" >



    <div class="card-body" style="background-color: whitesmoke;">



        <div class="card-header header-elements-inline">
          <h5 class="card-title"> <span class="font-weight-semibold" style="color: #2d7330" > {{invoice.doc_no}}</span>  Purchase Invoice Preview </h5>

        </div>


        <div class="card-body">


          <div class="row">

            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Customer</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invoice.ledger.name" readonly>
              </div>
            </div>

            <div class="col-md-1">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Type</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invtype" readonly>
              </div>
            </div>

            <div class="col-md-3">

            </div>

            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label  font-weight-semibold">A/c Date</label>
                <input type="date" class="form-control" v-if="voucher" v-model="invoice.doc_date" readonly >
              </div>
            </div>


            <div class="col-md-1">
              <div class="form-group form-group-material">
                <label class=" control-label font-weight-semibold">Invoice No</label>
                <input type="text" class="form-control" v-if="invoice" v-model="invoice.ref_no" readonly>
              </div>
            </div>


            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label  font-weight-semibold">Invoice Date</label>
                <input type="date" class="form-control" v-if="invoice" v-model="invoice.ref_date" readonly >
              </div>
            </div>

          </div>

          <div class="row">
            <!--  Detail Table -->
            <div class="table-responsive">

              <table id="mytable" class="table table-no-bordered">
                <thead style="background-color: lightgrey">
                <tr>
                  <th style="width:50px;">S.No</th>
                  <th >Item</th>
                  <th style="width:100px; text-align: right;">GST%</th>
                  <th style="width:100px; text-align: right;">Rate</th>
                  <th style="width:75px; text-align: right;">Qty</th>
                  <th style="width:100px; text-align: right;">Taxable</th>
                  <th style="width:100px; text-align: right;">IGST</th>
                  <th style="width:100px; text-align: right;">CGST</th>
                  <th style="width:100px; text-align: right;">SGST</th>
                  <th style="width:150px; text-align: right;">Net Amount</th>
                </tr>

                </thead>
                <tbody v-for="(detail,index) in invoice.list" >
                <tr style="padding: 0px; height: 40px;">
                  <td style="padding: 0px;text-align: center;"> {{index + 1}} </td>

                  <td style="padding: 0px;">
                    {{ detail.item.name}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{detail.gst_rate}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.rate)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.qty)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.taxable_amt)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.igst_amt)}}
                  </td>
                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.cgst_amt)}}
                  </td>
                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.sgst_amt)}}
                  </td>

                  <td style="padding: 0px;text-align: right;padding-right: 20px;">
                    {{ indianFormat(detail.net_amt)}}
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td class="text-right" ><span >{{ rowQtyTotal }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowTaxableTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowIgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowCgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowSgstTotal) }}</span></td>
                  <td class="text-right" >&#8377;<span >{{ indianFormat(rowAmountTotal) }}</span></td>


                </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Narration</label>
                <textarea class="form-control" v-if="invoice" v-model="invoice.remarks" readonly> </textarea>
              </div>
            </div>


            <div class="col-md-6">
              <h3 style="font-size: 48px;color: blue">
                &#8377;<span style="padding-right: 15px;">{{ indianFormat( rowAmountTotal + parseFloat(invoice.round_off) ) }}</span>
              </h3>
            </div>

            <div class="col-md-3 text-right">
              <div class="form-group row">
                <label class="col-form-label col-md-4 font-weight-semibold">Round Off</label>
                <div class="col-md-8">
                  <input type="number" class="form-control text-right" v-if="invoice" v-model="invoice.round_off" readonly>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4 font-weight-semibold">TDS</label>
                <div class="col-md-8">
                  <input type="number" class="form-control text-right" v-if="invoice" v-model="invoice.tds_amt" readonly>
                </div>
              </div>

            </div>



          </div>
        </div>

    </div>



  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  import { store } from '@/store/store.js'
  export default {
    name: 'PurchaseInvoicePreview',
    store,
    props: {
      invoice: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}')
      }
    },
    data(){
      return {
        rowQtyTotal:0.0,
        rowAmountTotal: 0.0,
        rowIgstTotal:0.0,
        rowCgstTotal:0.0,
        rowSgstTotal:0.0,
        rowTaxableTotal:0.0,
        invtype:'',
        ledgers:[],
        voucher: JSON.parse('{"id":"","code":0,"status":0,"finyear":0,"ledger":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","gstin":""},"instrument":"","pay_date":"0001-01-01","amount":0,"tds_amt":0,"remarks":""}')
      }
    },
    created(){
      this.$data.voucher.pay_date = moment().format('YYYY-MM-DD');
    },
    beforeMount(){
      let self = this;
      this.invoice.list.forEach(function (det) {
        self.rowQtyTotal += det.qty;
        self.rowIgstTotal += det.igst_amt;
        self.rowCgstTotal += det.cgst_amt;
        self.rowSgstTotal += det.sgst_amt;
        self.rowTaxableTotal += det.taxable_amt;
        self.rowAmountTotal += det.net_amt;
      });
      this.invtype = transactionTypeFormatter(this.invoice.type);
      this.invoice.round_off = this.invoice.round_off;
      this.voucher.amount = this.invoice.net_amount;
    },
    mounted(){
      // $('#divinvoice').hide();
      $('#divinvoice').slideUp();
      this.loadLedgers();

      // Collapse card
      var _cardActionCollapse = function() {
        var $cardCollapsedClass = $('.card-collapsed');

        // Hide if collapsed by default
        $cardCollapsedClass.children('.card-header').nextAll().hide();

        // Rotate icon if collapsed by default
        $cardCollapsedClass.find('[data-action=collapse]').addClass('rotate-180');

        // Collapse on click
        $('.card [data-action=collapse]:not(.disabled)').on('click', function (e) {
          var $target = $(this),
            slidingSpeed = 150;

          e.preventDefault();
          $target.parents('.card').toggleClass('card-collapsed');
          $target.toggleClass('rotate-180');
          $target.closest('.card').children('.card-header').nextAll().slideToggle(slidingSpeed);
        });
      };

      _cardActionCollapse();

    },
    methods:{
      indianFormat(val){
        return parseFloat(val).toFixed(2);
      },
      loadLedgers(){
        let self = this;

        const requestOptions = {
          mode:'cors',
          headers: userService.authHeader()
        };


        //fetch the Ledgers
        self.$data.ledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/groups/12,22,23/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.accepted){
            if(_.isArray(resp.data)){
              self.$data.ledgers = resp.data;
            }
          }else{
            swal (  {title:"Oops" ,  text: resp.message,  type: "error"});
          }
        }).catch(function (err) {
          swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
        });

      },

    }
  }
</script>

<style scoped>

</style>
